import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import IconButton from 'components/generics/Button';
import More from 'components/icons/More';
import { Link } from 'react-router-dom';
import { getAllStructureAdmin } from 'services/API/Administration';
import { displayErrorMessage } from 'utils/tools_functions';
import { LoadingContext } from 'utils/context';
import { sendEvent } from 'utils/Event';
import { StructureDataGrid } from './StructureDataGrid';
import AddEditStructureModal from './form/AddEditStructureModal';
import IStructureList from 'components/generics/Interface/Api/Response/Structures/IStructureList';
import { GridValidRowModel } from '@mui/x-data-grid-pro';
const Cooperative = () => {
  const [modal, setModal] = useState<boolean>(false);
  const [editData, setEditData] = useState<React.SetStateAction<GridValidRowModel | null | undefined>>(null);
  const [structures, setStructures] = useState<IStructureList[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { setFullScreen } = useContext(LoadingContext);

  useEffect(() => {
    getStructures();
    return () => {
      sendEvent('cancelHttpRequest');
    };
  }, []);

  const getStructures = () => {
    if (!localStorage.getItem('cooperative')) {
      setFullScreen(true);
    }
    getAllStructureAdmin()
      .then((resp) => setStructures(resp.data.data))
      .catch((err) => toast.error(displayErrorMessage('ERR_GET_COOP')))
      .finally(() => setFullScreen(false));
  };

  const formSuccess = () => {
    setModal(false);
    setIsEdit(false);
    setEditData(null);
  };

  return (
    <div className="section">
      <div className="main_container">
        <div className="contain-header">
          <h1 className="title_section">Liste des structures</h1>
          <div className="add_button_container">
            <IconButton
              text="Ajouter"
              onClick={() => {
                setModal(!modal);
                setIsEdit(false);
              }}
              icon={<More width={13} height={13} />}
            />
          </div>
        </div>
        <StructureDataGrid
          structures={structures}
          refreshData={getStructures}
          setModal={setModal}
          setIsEdit={setIsEdit}
          setEditData={setEditData}
        />
      </div>
      {modal && (
        <AddEditStructureModal
          modal={modal}
          setModal={setModal}
          isEdit={isEdit}
          onSubmitSuccess={formSuccess}
          edit={editData}
          getStructures={getStructures}
          setEditData={setEditData}
        />
      )}
    </div>
  );
};
export default Cooperative;
