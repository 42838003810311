// REACT
import { useCallback } from 'react';
// YARN
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridColumnVisibilityModel,
  GridGroupingColDefOverride,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  GridRowsProp,
  GridSortModel,
  GridValidRowModel,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { ClickAwayListener } from '@mui/material';
// SERVICES
import { customCultureDataGridTheme } from 'utils/Datagrid/CustomCultureDataGridUtils';
import { toast } from 'react-toastify';
import { CustomToolbar, customToolBarStyle } from 'utils/Datagrid/CustomGenericDataGridUtils';
import localeTextConstants from './CustomDataGridLocaleTextConstants';
// THEMES

type CustomCultureDataGridParams = {
  rows: GridRowsProp;
  columns: GridColDef[];
  processRowUpdate?: (newRow: any, oldRow: any) => any;
  loading?: boolean;
  onRowSelectionModelChange?: React.Dispatch<React.SetStateAction<GridRowSelectionModel>> | null;
  rowSelectionModel?: number[];
  sortModel?: GridSortModel;
  columnVisibilityModel: GridColumnVisibilityModel;
  setColumnVisibilityModel: React.Dispatch<React.SetStateAction<GridColumnVisibilityModel>>;
  rowModesModel: GridRowModesModel;
  setRowModesModel: React.Dispatch<React.SetStateAction<GridRowModesModel>>;
  toggleVisibilityFilter?: boolean | null;
  fromCultures?: boolean | null;
  isLargeGrid?: boolean;
  isEditCoProduct?: boolean;
  fromParcel?: boolean;
  isEditableIFT?: boolean;
  isEditInputCost?: boolean;
  isEditMetaculture?: boolean;
  from?: string | boolean;
};

type rowModesModelType = {
  [key: string]: {
    fieldToFocus: string;
    fromScrollBar: boolean;
    fromEscapeKeyDown?: boolean;
    mode: string;
  };
};

type CustomGridRowParams = GridRowParams & { field?: string };

export default function CustomCultureDataGrid({
  rows,
  columns,
  processRowUpdate,
  loading,
  onRowSelectionModelChange = null,
  rowSelectionModel = [],
  sortModel,
  columnVisibilityModel,
  setColumnVisibilityModel,
  rowModesModel,
  setRowModesModel,
  toggleVisibilityFilter = null,
  fromCultures = false,
  isLargeGrid = false,
  isEditCoProduct = false,
  fromParcel = false,
  isEditableIFT = false,
  isEditInputCost = false,
  isEditMetaculture = false,
  from = false,
}: CustomCultureDataGridParams) {
  const apiRef = useGridApiRef();

  const handleRowModesModelChange = useCallback((newModel: GridRowModel) => {
    setRowModesModel((prevRowModesModel) => {
      if (Object.keys(newModel).length > 1) {
        for (const key in newModel) {
          if (!newModel[key].hasOwnProperty('fieldToFocus') && newModel[key].mode === 'edit') {
            newModel[key].mode = 'view';
          }
        }
      } else {
        for (const key in newModel) {
          if (newModel[key].mode === 'view') {
            newModel[key].mode = 'edit';
          }
          if (newModel[key].hasOwnProperty('ignoreModifications')) {
            newModel[key].mode = 'view';
          }
        }

        if (Object.keys(prevRowModesModel).length) {
          for (const key in prevRowModesModel) {
            if ((prevRowModesModel as rowModesModelType)[key]?.fromScrollBar) {
              newModel[key].mode = 'edit';
              newModel[key].fromScrollBar = false;
            }
            if ((prevRowModesModel as rowModesModelType)[key]?.fromScrollBar === false) {
              newModel[key].mode = 'view';
            }
          }
        }
      }

      return newModel;
    });
  }, []);

  const handleUpdateError = (err: any) => {
    toast.error("Une erreur est survenue lors de l'édition");
  };

  const getTogglableColumns = (columns: GridColDef[]) => {
    return columns
      .filter(
        (column) =>
          column.field !== 'priceN5' &&
          column.field !== 'inputCostN5' &&
          column.field !== 'tfiHerbicide' &&
          column.field !== 'tfiWithoutHerbicide' &&
          column.field !== 'inputCostWithoutNitrogen' &&
          column.field !== 'inputCostWithoutNitrogenN5' &&
          column.field !== '__tree_data_group__' &&
          column.field !== 'metaCultureName'
      )
      .map((column) => column.field);
  };

  const getRowCount = () => {
    return <Box sx={{ marginRight: '16px' }}>Nombre total de lignes : {rows.length}</Box>;
  };

  const closeRowAfterScrollbarClick = () => {
    for (const key in rowModesModel) {
      if (
        rowModesModel[key]?.mode === 'edit' &&
        rowModesModel[key].hasOwnProperty('fromScrollBar')
      ) {
        apiRef.current.stopRowEditMode({ id: key });
      }
    }
  };

  const groupingColDef: GridGroupingColDefOverride<GridValidRowModel> = {
    width: 5,
    valueFormatter: () => '',
    headerName: '',
    align: 'right',
    hideDescendantCount: true,
  };

  const getTreeDataPath = (row: GridRowModel) => row.parentId;

  return (
    <ClickAwayListener onClickAway={closeRowAfterScrollbarClick}>
      <Box
        sx={{
          height: '70%',
        }}
      >
        <ThemeProvider theme={customCultureDataGridTheme}>
          <DataGridPro
            sx={{
              '& .MuiDataGrid-virtualScroller': {
                overflowX: fromCultures ? 'auto !important' : 'hidden',
              },
            }}
            apiRef={apiRef}
            editMode="row"
            onRowEditStop={(params: CustomGridRowParams, event: MuiEvent) => {
              const target = (event as React.SyntheticEvent).target as HTMLElement;
              if (
                target?.classList &&
                Object.keys(target?.classList).length > 0 &&
                Array.from(target?.classList).includes('MuiDataGrid-scrollbar')
              ) {
                const newModel = {
                  [params.id]: {
                    mode: GridRowModes.Edit,
                    fieldToFocus: params.field,
                    fromScrollBar: true,
                  },
                };
                handleRowModesModelChange(newModel);
              }
            }}
            onRowClick={closeRowAfterScrollbarClick}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            rows={rows}
            columns={columns}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleUpdateError}
            localeText={localeTextConstants}
            getRowClassName={(params) => {
              if (fromParcel && params.row.parentId.length > 1) {
                return 'MuiDataGrid-ParcelChild--row';
              }
              return params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd';
            }}
            loading={loading}
            disableColumnMenu
            disableColumnResize={true}
            getCellClassName={(params) => {
              if (rowModesModel?.[params.id]) {
                if (params?.field === 'inputCost') {
                  return isEditInputCost
                    ? 'MuiDataGrid--AllRows-editable'
                    : 'MuiDataGrid-cell--Not-editable';
                }
                if (params?.field === 'tfi') {
                  return isEditableIFT
                    ? 'MuiDataGrid--AllRows-editable'
                    : 'MuiDataGrid-cell--Not-editable';
                }
                if (params?.field === 'metaCode') {
                  return isEditMetaculture
                    ? 'MuiDataGrid--AllRows-editable'
                    : 'MuiDataGrid-cell--Not-editable';
                }
                if (params?.isEditable === false) {
                  if (
                    params?.field !== 'inputCost' &&
                    params?.field !== 'tfi' &&
                    params?.field !== 'actions' &&
                    params?.field !== '__check__' &&
                    params?.field !== '__tree_data_group__'
                  ) {
                    return params?.isEditable === false ? 'MuiDataGrid-cell--Not-editable' : '';
                  }
                }
              } else {
                if (params?.field === 'inputCost') {
                  return isEditInputCost
                    ? 'MuiDataGrid--AllRows-editable'
                    : 'MuiDataGrid--Not-editable';
                }
                if (params?.field === 'tfi') {
                  return isEditableIFT
                    ? 'MuiDataGrid--AllRows-editable'
                    : 'MuiDataGrid-cell--Not-editable';
                }
                if (from === 'fromCooperative' && params?.field === 'metaCode') {
                  return isEditMetaculture
                    ? 'MuiDataGrid--AllRows-editable'
                    : 'MuiDataGrid-cell--Not-editable';
                }
                if (
                  params?.field !== 'inputCost' &&
                  params?.field !== 'tfi' &&
                  params?.field !== 'actions' &&
                  params?.field !== '__check__' &&
                  params?.field !== 'metaCode'
                ) {
                  if (params?.isEditable === false) {
                    return 'MuiDataGrid--Not-editable';
                  } else {
                    return 'MuiDataGrid--AllRows-editable';
                  }
                }
              }
              return '';
            }}
            getEstimatedRowHeight={() => 80}
            getRowHeight={({ id, model, densityFactor }) => {
              if (
                (isEditCoProduct && model.coProduct === undefined) ||
                (isEditCoProduct && model.coProduct?.length)
              ) {
                return 'auto';
              }
              return isLargeGrid ? (model.coProduct?.length > 1 ? 'auto' : 80) : 80;
            }}
            checkboxSelection={toggleVisibilityFilter ? false : fromParcel ? false : true}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              if (onRowSelectionModelChange !== null) {
                onRowSelectionModelChange(newRowSelectionModel);
              }
            }}
            rowSelectionModel={rowSelectionModel}
            disableRowSelectionOnClick
            sortModel={sortModel}
            slots={{
              toolbar: CustomToolbar,
              ...(fromParcel && { footerRowCount: getRowCount }),
            }}
            slotProps={{
              basePopper: {
                sx: customToolBarStyle,
              },
              columnsManagement: {
                getTogglableColumns,
              },
            }}
            initialState={{
              pinnedColumns: {
                left: fromCultures ? [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'name'] : [],
                right: fromCultures ? ['actions'] : [],
              },
            }}
            disableColumnReorder={true}
            treeData={fromParcel ? true : false}
            getTreeDataPath={getTreeDataPath}
            groupingColDef={groupingColDef}
            defaultGroupingExpansionDepth={-1}
            isCellEditable={(params) => {
              if (
                ((params.field === 'surface' ||
                  params.field === 'isIrrigable' ||
                  params.field === 'parcel' ||
                  params.field === 'cultureN' ||
                  params.field === 'cultureN1' ||
                  params.field === 'groundType') &&
                  !params.row.isParent &&
                  params.row.parentId.length > 1) ||
                params.field === 'inputCost' ||
                params.field === 'tfi' ||
                params.field === 'metaCode'
              ) {
                return false;
              }
              return true;
            }}
          />
        </ThemeProvider>
      </Box>
    </ClickAwayListener>
  );
}
