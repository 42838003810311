// REACT
import { useContext, useEffect, useState } from 'react';
// SERVICES
import {
  cultureHover,
  loadResultMapTabData,
  parcelHoverCultureOpacity,
  sortList,
} from 'utils/Map/MapTabUtils';
import { getColorByName } from 'utils/culturesColors';
import { formatNumberWithSpaces } from 'utils';
import TotalMapTab from './TotalMapTab';
import { ExploitationContext } from 'utils/context';
import YearsMapTab from './YearsMapTab';
import { getPercentage } from 'utils/tools_functions';
// INTERFACES
import { ISuggestYears } from '../Interface/Simulation/ISuggestYears';
import { IParcelSimu } from '../Interface/Simulation/IParcelSimu';
import { IExploitationContext } from '../Interface/Context/IExploitationContext';
import { cultureAndOtherValue } from '../Interface/Simulation/ICultureAndOtherValue';
// THEMES
import './CulturesMapTab.style.scss';

type MapTabParams = {
  data: IParcelSimu[];
  header: Array<string>;
  isDynamic: boolean;
  year: number;
  setYear: Function;
  minYear: number;
  maxYear: number;
  yearIndex: number | null;
  suggestions: ISuggestYears[] | null;
  parcelHoverCultureName: Function | null;
  parentCallback: Function | null;
  previousCropMargin: cultureAndOtherValue<'grossMargin', number>[] | null;
  fromProduction: boolean;
  fromSynthesis: boolean;
  fromParcels: boolean;
};

const MapTab = ({
  data,
  header,
  isDynamic,
  year,
  setYear,
  minYear,
  maxYear,
  yearIndex = null,
  suggestions = null,
  parcelHoverCultureName = null,
  parentCallback = null,
  previousCropMargin = null,
  fromProduction = false,
  fromSynthesis = false,
  fromParcels = false,
}: MapTabParams) => {
  type culture = {
    id: number;
    name: String;
    color: string;
    irrigationWaterNeeded: number;
    isArable: boolean | null;
    year: number;
    surface: number;
  };
  type cultureByYear = {
    cultures: culture[];
    year: number;
    surface: number;
  };

  const { culturesColors } = useContext(ExploitationContext) as IExploitationContext;
  const [culturesByYear, setCulturesByYear] = useState<cultureByYear[]>([]);
  const [totalSurface, setTotalSurface] = useState<number>(0);

  useEffect(() => {
    if (data) {
      loadResultMapTabData(
        data,
        setCulturesByYear,
        setTotalSurface,
        culturesColors,
        minYear,
        maxYear,
        fromParcels ? true : false
      );
    }
  }, [data, year, yearIndex]);

  const getPreviousOrFutureCultureGrossMarginByHa = (culture: culture) => {
    let cultureGrossMargin: number | undefined = 0;

    if (yearIndex === null) {
      return;
    }

    if (yearIndex < 0) {
      cultureGrossMargin = previousCropMargin?.find(
        (margin) => margin?.culture?.id === culture.id
      )?.grossMargin;
    }
    if (suggestions !== null && yearIndex in suggestions && yearIndex >= 0) {
      cultureGrossMargin = suggestions[yearIndex]?.cropsGrossMargin?.find(
        (margin) => margin?.culture?.id === culture?.id
      )?.grossMargin;
    }

    if (cultureGrossMargin === undefined) {
      return;
    }

    return formatNumberWithSpaces(Math.round(cultureGrossMargin / culture?.surface));
  };

  const getCultureGrossMargin = (culture: culture) => {
    if (yearIndex === null) {
      return;
    }

    let grossMargin: number | undefined = 0;

    if (yearIndex < 0) {
      grossMargin = previousCropMargin?.find(
        (margin) => margin?.culture?.id === culture.id
      )?.grossMargin;
    } else if (suggestions !== null && yearIndex in suggestions && yearIndex >= 0) {
      grossMargin = suggestions[yearIndex].cropsGrossMargin?.find(
        (margin) => margin?.culture?.id === culture?.id
      )?.grossMargin;
    }

    if (grossMargin === undefined) {
      return;
    }

    return formatNumberWithSpaces(Math.round(grossMargin));
  };

  return (
    <div className="cultures-map-tab-container">
      {!fromProduction && (
        <YearsMapTab year={year} minYear={minYear} maxYear={maxYear} setYear={setYear} />
      )}
      <div
        className={
          fromProduction ? 'table-container sticky-table table-production' : 'table-container'
        }
      >
        {!culturesByYear?.some((culture) => culture.year === year) && (
          <div
            style={{
              fontSize: 'bold',
              fontFamily: 'Rubik',
              padding: '20px',
              color: '#707070',
            }}
          >
            Pas de cultures pour cette année
          </div>
        )}
        {culturesByYear?.some((culture) => culture.year === year) && (
          <table
            className={
              isDynamic ? 'cultures-map-tab-table dynamic-table' : 'cultures-map-tab-table'
            }
          >
            <thead
              style={{ fontSize: 'bold', fontFamily: 'Rubik' }}
              className={
                fromProduction ? 'table_head sticky-table' : 'table_head table-synthesis-sticky'
              }
            >
              <tr>
                {header.map((header) => (
                  <th
                    key={header}
                    scope="col"
                    className={header === 'Marge (€/ha)' ? 'last_column_name' : 'column_name'}
                    colSpan={header === 'Marge' || header === 'Surface' ? 2 : 1}
                  >
                    <span>{header}</span>
                  </th>
                ))}
              </tr>
              {header.includes('Surface') && (
                <tr className="column_subName">
                  <th colSpan={2}></th>
                  <th colSpan={1}>(ha)</th>
                  <th colSpan={1}>(%)</th>
                  {header.includes('Marge (€/ha)') && <th colSpan={2}></th>}
                </tr>
              )}
              {header.includes('Marge') && (
                <tr className="column_subName">
                  <th colSpan={2}></th>
                  <th colSpan={1}>(€)</th>
                  <th colSpan={1}>(€ / ha)</th>
                </tr>
              )}
            </thead>
            <tbody>
              {sortList(culturesByYear, fromProduction ? year + (yearIndex ?? 0) : year)
                ?.filter((culture: culture) => culture.id !== undefined)
                .map((culture: culture, i: number) => (
                  <tr
                    key={i}
                    className="tr"
                    style={{
                      opacity: parcelHoverCultureOpacity(culture.name, parcelHoverCultureName),
                    }}
                    {...(isDynamic && {
                      onMouseEnter: () => cultureHover(culture.name, parentCallback),
                      onMouseLeave: () => cultureHover('null', parentCallback),
                    })}
                  >
                    <td key={getColorByName(culture.name, culturesColors)}>
                      <div
                        className="culture-color"
                        style={{ background: '#' + getColorByName(culture.name, culturesColors) }}
                      ></div>
                    </td>

                    {fromProduction && (
                      <>
                        <td className="culture-line-clamp" key={culture.name as string}>
                          {culture.name}
                        </td>
                        <td key={'marge_total'}>{getCultureGrossMargin(culture)}</td>
                        <td key={'marge_ha'}>
                          {getPreviousOrFutureCultureGrossMarginByHa(culture)}
                        </td>
                      </>
                    )}
                    {(fromSynthesis || fromParcels) && (
                      <>
                        <td key={culture.name as string}>
                          {culture.name ?? 'Pas de culture associée'}
                        </td>
                        <td key={culture.surface}>{Math.round(culture.surface * 100) / 100}</td>
                        <td key={'percent'}>{getPercentage(culture.surface, totalSurface)}</td>
                        {fromSynthesis && (
                          <td key={'marge'}>
                            {getPreviousOrFutureCultureGrossMarginByHa(culture) ?? '-'}
                          </td>
                        )}
                      </>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        <TotalMapTab totalSurface={totalSurface} fromProduction={fromProduction} />
      </div>
    </div>
  );
};

export default MapTab;
